import { useSelector } from "react-redux"

const useDirection = () => {
  const languages = useSelector((state) => state.configuration?.languages)
  const currentLanguageCode = useSelector((state) => state.currentLanguageCode)
  const languageDirection = languages?.find((language) => language.code === currentLanguageCode)?.direction
  return languageDirection
}

export default useDirection
